.slider-popup {
  max-width: 70vw;
}

@media screen and (max-width: 767px) {
  .slider-popup {
    max-width: 90vw;
  }
}

.slider-popup .swiper {
  flex-direction: column;
}


.slider-popup__image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.slider-popup .swiper-button-next {
  padding: 0 8px;
  position: absolute;
  top: calc(50% - 27px);
  width: 30px;
  height: 54px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  transition: .25s;
  background-image: url(../../assets/images/right.svg);
  right: -62px;
}

.slider-popup .swiper-button-prev {
  padding: 0 8px;
  position: absolute;
  top: calc(50% - 27px);
  width: 30px;
  height: 54px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  transition: .25s;
  background-image: url(../../assets/images/left.svg);
  left: -62px;
}

@media screen and (max-width: 767px) {
  .slider-popup .swiper-button-next {
    display: none;
  }

  .slider-popup .swiper-button-prev {
    display: none;
  }
}

.slider-popup .swiper-button-disabled {
  opacity: .3;
}

.slider-popup .swiper-pagination {
  margin-top: 10px;
  display: none;
  gap: 20px;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .slider-popup .swiper-pagination {
    display: flex;
  }
}

.slider-popup .swiper-pagination-bullet {
  background-color: #cbba92;
  width: 10px;
  aspect-ratio: 1/1;
  cursor: pointer;
  border-radius: 50%;
}

@media screen and (max-width: 767px) {
  .slider-popup .swiper-pagination-bullet {
    background-color: #fff;
    width: 7px;
  }
}

.slider-popup .swiper-pagination-bullet-active {
  background-color: #6c6c57;
}
