@font-face {
  font-family: 'Bebas Neue';
  src: url('BebasNeue-Thin.woff2') format('woff2'),
       url('BebasNeue-Thin.woff') format('woff');
  font-weight: 100;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('BebasNeueLight.woff2') format('woff2'),
       url('BebasNeueLight.woff') format('woff');
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('BebasNeueBook.woff2') format('woff2'),
       url('BebasNeueBook.woff') format('woff');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('BebasNeueRegular.woff2') format('woff2'),
       url('BebasNeueRegular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url('BebasNeueBold.woff2') format('woff2'),
       url('BebasNeueBold.woff') format('woff');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

