.subscribe {
  max-width: 1280px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
}

@media screen and (max-width: 767px) {
  .subscribe {
    padding: 30px 0;
  }
}

.subscribe__title {
  margin: 0;
  line-height: 1;
  font-size: 52px;
  font-weight: 600;
  color: #6c6c57;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .subscribe__title {
    font-size: 35px;
  }
}

.subscribe__form {
  display: flex;
  gap: 10px;
  margin-top: 25px;
  font-family: Inter, Arial, sans-serif;
}

@media screen and (max-width: 767px) {
  .subscribe__form {
    flex-direction: column;
    gap: 0;
    width: 100%;
    max-width: 300px;
  }
}

.subscribe__field {
  display: flex;
  flex-direction: column;
  max-width: 210px;
}

@media screen and (max-width: 767px) {
  .subscribe__field {
    max-width: none;
    width: 100%;
  }
}

.subscribe__input {
  border: 1px solid #6c6c57;
  border-radius: 7px;
  font-size: 20px;
  padding: 10px;
  font-weight: 400;
  cursor: pointer;
  transition: .25s;
}

.subscribe__input::placeholder {
  transition: .25s;
  color: rgba(108, 108, 87, .5);
}

.subscribe__input_type_error {
  border: 1px solid red;
}

.subscribe__input:focus {
  outline: none;
  color: #6c6c57;
}

.subscribe__input:focus::placeholder {
  color: transparent;
}

.subscribe__input-error {
  color: red;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  min-height: 24px;
  padding: 5px 0;
  transition: opacity .2s;
}

.subscribe__submit-btn {
  width: 134px;
  height: 45px;
  background-image: url("../../assets/images/subscribe.svg");
  background-color: #6c6c57;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 20px;
  border: none;
  border-radius: 7px;
  transition: .25s;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .subscribe__submit-btn {
    width: 100%;
  }
}

.subscribe__submit-btn:disabled {
  background-color: #e2e2dd;
  cursor: not-allowed;
}

.subscribe__safe {
  text-align: center;
  margin: 0;
  font-family: Inter, Arial, sans-serif;
  font-size: 12px;
  color: #cbba92;
}

@media screen and (max-width: 767px) {
  .subscribe__safe {
    margin-top: 10px;
    max-width: 300px;
  }
}

.subscribe__policy {
  color: #6c6c57;
}

.subscribe__status {
  min-height: 50px;
  display: flex;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity .25s, visibility .25s;
}

.subscribe__status_visible {
  visibility: visible;
  opacity: 1;
}

.subscribe__status-message {
  color: #6c6c57;
  margin: 0;
  text-align: center;
  font-size: 22px;
  font-weight: 400;
  line-height: 1;
}

.subscribe__status-message_type_error {
  color: red;
}
