.shop {
  max-width: 1280px;
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 50px 0;
}

@media screen and (max-width: 767px) {
  .shop {
    gap: 30px;
    padding: 30px 0;
  }
}

.shop__description {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 60px;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .shop__description {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.shop__title {
  margin: 0;
  width: 262px;
  line-height: 1;
  font-size: 52px;
  font-weight: 600;
  color: #6c6c57;
}

@media screen and (max-width: 1023px) {
  .shop__title {
    width: 25.5vw;
    font-size: 5vw;
  }
}

@media screen and (max-width: 767px) {
  .shop__title {
    width: 100%;
    font-size: 10vw;
  }
}

.shop__main-text {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;
}

.shop__paragraph {
  font-family: Inter, Arial, sans-serif;
  margin: 0;
  line-height: 1.4;
  font-size: 22px;
  font-weight: normal;
  color: #6c6c57;
  white-space: pre-wrap;
}

@media screen and (max-width: 1023px) {
  .shop__paragraph {
    font-size: 2.35vw;
  }
}

@media screen and (max-width: 767px) {
  .shop__paragraph {
    font-size: 17px;
  }
}

.shop__social-link {
  padding: 15px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  background-color: #6c6c57;
  border-radius: 7px;
  transition: .25s;
  -webkit-tap-highlight-color: transparent;
}

@media screen and (max-width: 657px) {
  .shop__social-link {
    padding: 3vw;
  }
}

.shop__link-text {
  background-image: url("../../assets/images/group.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 20px;
  aspect-ratio: 10/3;
}

@media screen and (max-width: 657px) {
  .shop__link-text {
    height: 6vw;
  }
}

.shop__social-icon {
  height: 20px;
  aspect-ratio: 8/5;
  background-image: url("../../assets/images/vk.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media screen and (max-width: 657px) {
  .shop__social-icon {
    height: 6vw;
  }
}
