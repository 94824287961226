.button-up {
  position: sticky;
  visibility: hidden;
  opacity: 0;
  bottom: 20px;
  left: 20px;
  margin: 0 0 20px 20px;
  transition: .25s;
  background-color: #cbba92;
  border-radius: 10px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: start;
  -webkit-tap-highlight-color: transparent;
  background-image: url("../../assets/images/up.svg");
  background-repeat: no-repeat;
  background-size: 40px;
  background-position: center;
  cursor: pointer;
}

@media screen and (hover: hover) {
  .button-up:hover {
    background-color: #6c6c57;
  }
}

.button-up_visible {
  visibility: visible;
  opacity: 1;
}
