.nav-tab_type_header {
  background-color: #6c6c57;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
}

@media screen and (max-width: 767px) {
  .nav-tab_type_header {
    display: block;
    height: 51px;
    background: #6c6c57;
    transition: all 0.5s ease-out, background 1s ease-out;
    transition-delay: 0.2s;
    overflow: hidden;
    padding: 0;
  }
}

.nav-tab__fixed-logo-container {
  display: flex;
}

@media screen and (max-width: 767px) {
  .nav-tab__fixed-logo-container {
    display: none;
  }
}

.nav-tab__fixed-logo {
  text-decoration: none;
  font-size: 20px;
  line-height: 1;
  color: #fff;
  opacity: .8;
  cursor: pointer;
}

@media screen and (hover: hover) {
  .nav-tab__fixed-logo:hover {
    transition: .25s;
    color: #cbba92;
  }
}

.nav-tab__mobile-content {
  display: none;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .nav-tab__mobile-content {
    display: grid;
  }
}

.nav-tab__mobile-content {
  display: none;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .nav-tab__mobile-content {
    display: grid;
  }
}

.nav-tab__burger-container {
  position: relative;
  display: inline-block;
  height: 50px;
  width: 50px;
  cursor: pointer;
  transform: rotate(0deg);
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.nav-tab__burger {
  width: 35px;
  height: 8px;
  position: relative;
  display: block;
  margin: 0 auto;
  top: 50%;
}

.nav-tab__bar {
  width: 100%;
  height: 3px;
  display: block;
  position: relative;
  background: #FFF;
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition-delay: 0s;
}

.nav-tab__bar-top {
  transform: translateY(-10px) rotate(0deg);
}

.nav-tab__bar-middle {
  transform: translateY(-4px) rotate(0deg);
}

.nav-tab__bar_top {
  transform: translateY(2px) rotate(0deg);
}

.nav-tab__mobile-logo {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

.nav-tab__mobile-title {
  text-decoration: none;
  color: rgba(255, 255, 255, .8);
  margin: 0;
  font-size: 25px;
  line-height: 1;
  font-weight: 400;
  -webkit-padding-before: 7px;
}

.nav-tab__menu-options {
  display: flex;
  justify-content: center;
  gap: 20px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-tab__menu-options_type_footer {
  flex-direction: column;
  gap: 9px;
}

@media screen and (max-width: 767px) {
  .nav-tab__menu-options {
    padding: 0 48px 0;
    flex-direction: column;
    gap: 0;
  }

  .nav-tab__menu-options_type_footer {
    padding: 0;
    gap: 20px;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .nav-tab__menu-option_type_header {
    border-bottom: 1px solid rgba(255, 255, 255, .8);
    padding: 20px 0;
    transform: scale(0) translateY(-30px);
    opacity: 0;
    transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  }

  .nav-tab__menu-option:nth-child(1) {
    transition-delay: 0.49s;
  }

  .nav-tab__menu-option:nth-child(2) {
    transition-delay: 0.42s;
  }

  .nav-tab__menu-option:nth-child(3) {
    transition-delay: 0.35s;
  }

  .nav-tab__menu-option:nth-child(4) {
    transition-delay: 0.28s;
  }

  .nav-tab__menu-option:nth-child(5) {
    transition-delay: 0.21s;
  }

  .nav-tab__menu-option:nth-child(6) {
    transition-delay: 0.14s;
  }

  .nav-tab__menu-option:nth-child(7) {
    transition-delay: 0.07s;
  }
}

@media screen and (max-width: 767px) {
  .nav-tab_opened {
    height: 100vh;
    background-color: #6c6c57;
    transition: all 0.3s ease-in, background 0.5s ease-in;
    transition-delay: 0.25s;
    overflow: auto;
  }

  .nav-tab_opened .nav-tab__burger-container {
    transform: rotate(90deg);
  }

  .nav-tab_opened .nav-tab__bar {
    transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transition-delay: 0.2s;
  }

  .nav-tab_opened .nav-tab__bar-top {
    transform: translateY(-2px) rotate(45deg);
  }

  .nav-tab_opened .nav-tab__bar-middle {
    transform: translateY(-5px) rotate(-45deg);
  }

  .nav-tab_opened .nav-tab__bar_top {
    transform: translateY(50px);
  }

  .nav-tab_opened .nav-tab__menu-option {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }

  .nav-tab_opened .nav-tab__menu-option:nth-child(1) {
    transition-delay: 0.27s;
  }

  .nav-tab_opened .nav-tab__menu-option:nth-child(2) {
    transition-delay: 0.34s;
  }

  .nav-tab_opened .nav-tab__menu-option:nth-child(3) {
    transition-delay: 0.41s;
  }

  .nav-tab_opened .nav-tab__menu-option:nth-child(4) {
    transition-delay: 0.48s;
  }

  .nav-tab_opened .nav-tab__menu-option:nth-child(5) {
    transition-delay: 0.55s;
  }

  .nav-tab_opened .nav-tab__menu-option:nth-child(6) {
    transition-delay: 0.62s;
  }

  .nav-tab_opened .nav-tab__menu-option:nth-child(7) {
    transition-delay: 0.69s;
  }

  .nav-tab_opened .nav-tab__menu-option:nth-child(8) {
    transition-delay: 0.76s;
  }

  .nav-tab_opened .nav-tab__menu-option:nth-child(9) {
    transition-delay: 0.83s;
  }

  .nav-tab_opened .nav-tab__menu-option:nth-child(10) {
    transition-delay: 0.90s;
  }
}
