.about {
  padding: 50px 0;
  max-width: 1280px;
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

@media screen and (max-width: 767px) {
  .about {
    gap: 50px;
    padding: 30px 0;
  }
}

.about__heading {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 60px;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .about__heading {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.about__title {
  margin: 0;
  width: 262px;
  line-height: 1;
  font-size: 52px;
  font-weight: 600;
  color: #6c6c57;
}

@media screen and (max-width: 1023px) {
  .about__title {
    width: 25.5vw;
    font-size: 5vw;
  }
}

@media screen and (max-width: 767px) {
  .about__title {
    width: 100%;
    font-size: 10vw;
  }
}

.about__map {
  max-width: 1280px;
  aspect-ratio: 3/1;
}

@media screen and (max-width: 767px) {
  .about__map {
    width: 100%;
    aspect-ratio: 1/1;
  }
}

.about__frame {
  width: 100%;
  height: 100%;
  border: none;
}

.about__info {
  display: flex;
  flex-direction: column;
  gap: 100px;
  padding-top: 50px;
}

@media screen and (max-width: 767px) {
  .about__info {
    padding-top: 0;
    gap: 50px;
  }
}
