.footer {
  background-color: #6c6c57;
  width: 100%;
  min-height: 151px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  gap: 20px;
}

.footer__content {
  width: 100%;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .footer__content {
    width: 90%;
    display: grid;
    justify-items: center;
    gap: 45px;
    grid-template-areas:
    'info'
    'menu'
    'logo';
  }
}

.footer__logo {
  margin: 0 60px 0 20px;
  width: 265px;
  height: 131px;
  grid-area: logo;
}

@media screen and (max-width: 1023px) {
  .footer__logo {
    width: 25%;
    margin-right: 30px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 767px) {
  .footer__logo {
    width: 288px;
    margin: 0;
  }
}

.footer__info {
  margin: 0 20px 0 auto;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: flex-end;
  grid-area: info;
}

@media screen and (max-width: 1023px) {
  .footer__info {
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px) {
  .footer__info {
    flex-direction: column-reverse;
    align-items: center;
    margin: 0;
  }
}

.footer__title {
  width: 100%;
  margin: 0;
  color: #fff;
  font-weight: 600;
  font-size: 40px;
  line-height: 1;
  text-align: end;
}

@media screen and (max-width: 1023px) {
  .footer__title {
    font-size: 3.9vw;
  }
}

@media screen and (max-width: 767px) {
  .footer__title {
    text-align: center;
    font-size: 38px;
  }
}

.footer__phone-number {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  font-size: 40px;
  line-height: 1;
}

@media screen and (max-width: 1023px) {
  .footer__phone-number {
    font-size: 3.9vw;
  }
}

@media screen and (max-width: 767px) {
  .footer__phone-number {
    font-size: 38px;
    -webkit-tap-highlight-color: transparent;
  }
}

.footer__copyright {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

@media screen and (max-width: 767px) {
  .footer__copyright {
    justify-content: center;
    width: 90%;
  }
}

.footer__copyright-link {
  text-decoration: none;
  margin-left: 20px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  color: #fff;
}

@media screen and (max-width: 1023px) {
  .footer__copyright-link {
    margin-left: 10px;
  }
}

@media screen and (max-width: 767px) {
  .footer__copyright-link {
    margin-right: 0;
  }
}
