@import url(./assets/vendor/normalize.css);
@import url(./assets/fonts/Bebas_Neue/bebas_neue.css);
@import url(./assets/fonts/Inter/inter.css);

.page {
  width: 100%;
  font-family: 'Bebas Neue', Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  justify-content: space-between;
}

.link-hover {
  transition: 0.25s;
}

@media screen and (hover: hover) {
  .link-hover:hover {
    color: #cbba92;
  }
}

.button-hover {
  transition: 0.25s;
  cursor: pointer;
}

.button-hover:focus-visible {
  outline: none;
}

@media screen and (hover: hover) {
  .button-hover:enabled:hover {
    background-color: #cbba92;
  }
}

.line {
  margin-top: 25px;
  width: 100%;
  height: 0;
  border: 1px solid #6c6c57;
  opacity: .5;
}

@media screen and (max-width: 1023px) {
  .line {
    margin-top: calc(2.5vw - 1px);
  }
}

@media screen and (max-width: 767px) {
  .line {
    margin-top: 0;
  }
}

.swiper {
  display: flex;
  overflow: hidden;
}

.swiper-wrapper {
  display: flex;
}

.swiper-slide {
  flex-shrink: 0;
  touch-action: pan-y;
}

.popup-close-btn {
  background-image: url("./assets/images/close-button.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: transparent;
  position: absolute;
  top: -40px;
  right: -40px;
  width: 32px;
  height: 32px;
  padding: 0;
  border: none;
  transition: opacity 0.25s;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

@media (hover: hover) {
  .popup-close-btn:hover {
    opacity: .6;
  }
}

@media screen and (max-width: 767px) {
  .popup-close-btn {
    right: 0;
    top: -41px;
    width: 25px;
    height: 25px;
  }
}
