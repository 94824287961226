.company-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .company-info {
    flex-direction: column;
    align-items: start;
    gap: 30px;
  }
}

.company-info__desc {
  max-width: 31%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  color: #6c6c57;
}

@media screen and (max-width: 767px) {
  .company-info__desc {
    max-width: 100%;
    gap: 10px;
  }
}

.company-info__title {
  font-size: 52px;
  font-weight: 600;
  line-height: 1;
  margin: 0;
}


@media screen and (max-width: 1279px) {
  .company-info__title {
    font-size: 4.1vw;
  }
}

@media screen and (max-width: 767px) {
  .company-info__title {
    font-size: 7.5vw;
  }
}

.company-info__subtitle {
  font-size: 30px;
  font-weight: 400;
  line-height: 1.3;
  margin: 0;
  white-space: pre-wrap;
}

@media screen and (max-width: 1279px) {
  .company-info__subtitle {
    font-size: 2.35vw;
  }
}

@media screen and (max-width: 767px) {
  .company-info__subtitle {
    font-size: 20px;
  }
}


.company-info__slider {
  max-width: 60%;
  width: 100%;
  display: block;
}

@media screen and (max-width: 767px) {
  .company-info__slider {
    max-width: none;
  }
}

.company-info__slide {
  width: 100%;
}

.company-info__img {
  display: block;
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

@media screen and (max-width: 767px) {
  .company-info__img {
    max-width: none;
  }
}

.company-info .swiper-pagination {
  margin-top: 10px;
  display: none;
  gap: 20px;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .company-info .swiper-pagination {
    display: flex;
  }
}

.company-info .swiper-pagination-bullet {
  background-color: #cbba92;
  width: 10px;
  aspect-ratio: 1/1;
  cursor: pointer;
  border-radius: 50%;
}

.company-info .swiper-pagination-bullet-active {
  background-color: #6c6c57;
}
