.header {
  width: 100%;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 1;
}

.header__company-info {
  padding: 0 20px;
  height: 64px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  background-color: #fff;
}

@media screen and (max-width: 1023px) {
  .header__company-info {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 767px) {
  .header__company-info {
    display: none;
  }
}

.header__company-info_hide {
  display: none;
}

.header__logo {
  width: 183px;
  z-index: 1;
  margin-top: 2px;
}

@media screen and (max-width: 1023px) {
  .header__logo {
    width: 140px;
    margin-top: 17px;
  }
}

.header__title {
  color: #6c6c57;
  font-size: 40px;
  font-weight: 600;
  line-height: 1;
  margin: 15px 0 0;
}

@media screen and (max-width: 1023px) {
  .header__title {
    font-size: 4vw;
    margin-top: calc(32px - 1.4vw);
  }
}

.header__social-link {
  justify-self: end;
  width: 40px;
  height: 40px;
  background-color: #6c6c57;
  border-radius: 8px;
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__social-icon {
  width: 24px;
  height: 24px;
  background-image: url("../../assets/images/vk.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
