.feedback-button {
  width: 60px;
  height: 60px;
  background-color: #cbba92;
  border-radius: 50%;
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  transition: .25s linear;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.2);
  -webkit-tap-highlight-color: transparent;
}

@media screen and (hover: hover){
  .feedback-button:hover {
    background-color: #8b8b71;
  }
}

.feedback-button__icon {
  background-image: url("../../assets/images/letter.svg");
  width: 30px;
  height: 30px;
  background-size: 30px;
  background-position: center;
  background-repeat: no-repeat;
}

.feedback-button__rings {
  border: 1px solid #cbba92;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto;
  width: auto;
  border-radius: 50%;
  animation-duration: 2.0s;
  animation-name: pulse_1;
  animation-iteration-count: infinite;
  z-index: -1;
}

@media screen and (hover: hover){
  .feedback-button:hover .feedback-button__rings {
    border-color: #8b8b71;
  }
}

.feedback-button__rings:nth-child(2) {
  animation-name: pulse_2;
}

.feedback-button__rings:nth-child(3) {
  animation-name: pulse_3;
}

@keyframes pulse_1 {
  from {
    opacity: 1;
    transform: scale(0.9, 0.9);
  }

  to {
    opacity: 0;
    transform: scale(1.3, 1.3);
  }
}

@keyframes pulse_2 {
  from {
    opacity: 1;
    transform: scale(0.8, 0.8);
  }

  to {
    opacity: 0;
    transform: scale(1.5, 1.5);
  }
}

@keyframes pulse_3 {
  from {
    opacity: 1;
    transform: scale(0.8, 0.8);
  }

  to {
    opacity: 0;
    transform: scale(1.7, 1.7);
  }
}
