.content {
  margin-top: 115px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .content {
    margin-top: 50px;
  }
}
