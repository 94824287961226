.feedback-popup {
  background-color: #fff;
  border-radius: 10px;
  max-width: 450px;
  width: 100%;
  box-sizing: border-box;
  color: #6c6c57;
}

@media screen and (max-width: 767px) {
  .feedback-popup {
    max-width: 90vw;
  }
}

.feedback-popup__content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  max-height: 77vh;
  margin: 5px;
  padding: 30px;
}

@media screen and (max-width: 1025px) {
  .feedback-popup__content {
    padding: 20px;
  }
}

.feedback-popup__content::-webkit-scrollbar {
  width: 6px;
  height: 80%;
}

.feedback-popup__content::-webkit-scrollbar-track {
  background: #e2e2dd;
  border-radius: 2.5px;
}

.feedback-popup__content::-webkit-scrollbar-thumb {
  background-color: #6c6c57;
  border-radius: 2.5px;
}

.feedback-popup__title {
  margin: 0;
  line-height: 1;
  font-size: 35px;
  font-weight: 600;
  color: #6c6c57;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .feedback-popup__title {
    font-size: 30px;
  }
}

.feedback-popup__subtitle {
  margin: 0;
  line-height: 1.2;
  font-size: 20px;
  color: #6c6c57;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .feedback-popup__subtitle {
    font-size: 17px;
  }
}

.feedback-popup__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-family: Inter, Arial, sans-serif;
}

.feedback-popup__question {
  border: none;
  border-radius: 7px;
  padding: 5px;
  transition: .25s;
  align-items: center;
  gap: 5px;
  background-color: #e2e2dd;
  margin: 15px 0;
  display: none;
}

.feedback-popup__question_visible {
  display: flex;
}

.feedback-popup__question-input {
  border: none;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: transparent;
  font-size: 15px;
  font-weight: 400;
  padding: 0;
  text-align: center;
}

.feedback-popup__question-delete-btn {
  padding: 0;
  width: 15px;
  height: 15px;
  border: none;
  display: block;
  flex-shrink: 0;
  background-color: transparent;
  background-image: url("../../assets/images/delete-button.svg");
  background-position: center;
  background-size: contain;
  cursor: pointer;
}

.feedback-popup__field {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.feedback-popup__label {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 13px;
}

.feedback-popup__input {
  border: 1px solid #6c6c57;
  border-radius: 7px;
  font-size: 15px;
  line-height: 17px;
  padding: 7px;
  font-weight: 400;
  cursor: pointer;
  transition: .25s;
}

.feedback-popup__input:focus-visible {
  outline: none;
}

.feedback-popup__input_type_error {
  border-color: red;
}

.feedback-popup__input-error {
  color: red;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  min-height: 12px;
}

.feedback-popup__label_type_textarea {
  position: relative;
}

.feedback-popup__label_type_textarea:before {
  content: "";
  border-top: 1px solid #6c6c57;
  width: 12px;
  transform: rotate(-45deg);
  background: transparent;
  position: absolute;
  right: 1px;
  bottom: 6px;
  pointer-events: none;
}

.feedback-popup__label_type_textarea:after {
  content: "";
  border-top: 1px solid #6c6c57;
  width:7px;
  transform: rotate(-45deg);
  position: absolute;
  bottom: 4px;
  right: 1px;
  pointer-events: none;
}

.feedback-popup__textarea {
  z-index: 100;
  background-color: transparent;
  width: 100%;
  resize: vertical;
  box-sizing: border-box;
  border: 1px solid #6c6c57;
  border-radius: 7px;
  font-size: 15px;
  line-height: 17px;
  padding: 7px;
  font-weight: 400;
  cursor: pointer;
  transition: border-color .25s;
  position: relative;
  overflow: hidden;
}

.feedback-popup__textarea::-webkit-resizer{
  display:none;
}

.feedback-popup__textarea:focus-visible {
  outline: none;
}

.feedback-popup__textarea_type_error {
  border-color: red;
}

.feedback-popup__submit-btn {
  width: 100%;
  height: 45px;
  background-color: #6c6c57;
  background-image: url("../../assets/images/send.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 18px;
  border: none;
  border-radius: 7px;
  transition: .25s;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

.feedback-popup__submit-btn:disabled {
  background-color: #e2e2dd;
  cursor: not-allowed;
}

.feedback-popup__safe {
  margin: 0;
  font-family: Inter, Arial, sans-serif;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #cbba92;
}

.feedback-popup__policy {
  color: #6c6c57;
}

.feedback-popup__message {
  font-family: Inter, Arial, sans-serif;
  text-align: center;
  font-size: 16px;
  line-height: 1.2;
}

.feedback-popup__message_type_error {
  color: red;
}
