.popup {
  z-index: 1000;
  width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, .9);
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: .25s linear;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup_opened {
  opacity: 1;
  visibility: visible;
}

.popup-content {
  position: relative;
  transform: scale(0, 0);
  transform-origin: center center;
  transition: .5s;
}

.popup_opened .popup-content {
  transform: scale(1, 1);
}

.popup__close-btn {
  background-image: url("../../assets/images/close-button.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: transparent;
  position: absolute;
  top: -40px;
  right: -40px;
  width: 32px;
  height: 32px;
  padding: 0;
  border: none;
  transition: opacity 0.25s;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

@media (hover: hover) {
  .popup__close-btn:hover {
    opacity: .6;
  }
}

@media screen and (max-width: 767px) {
  .popup__close-btn {
    right: 0;
    top: -41px;
    width: 25px;
    height: 25px;
  }
}
